import useSWRInfinite from 'swr/infinite'
import {
    InboxNotification,
    NotificationCategory,
    NotificationService,
    CursorPage_InboxNotification_,
    UserNotificationDeliveryType,
} from 'src/generated'
import { FunctionStatusType } from './sharedTypes'
import useSWRImmutable from 'swr/immutable'
import { useUserSettings } from './user_settings'

const NOTIFICATION_REFRESH_ONE_MINUTE_INTERVAL = 1000 * 60

type NotificationsInputType = {
    rollbackOnErrorFn: (error: unknown) => boolean
    categories?: NotificationCategory[]
}

type NotificationsReturnType = {
    notifications: InboxNotification[]
    mutations: {
        deleteNotificationAndReturnIsRead: (id: string) => boolean
        markAsRead: (ids?: string[]) => void
    }
    hasMore: boolean
    setSize: (size: number) => void
} & FunctionStatusType

type NotificationsFunc = (
    input: NotificationsInputType
) => NotificationsReturnType

const useNotifications: NotificationsFunc = ({
    categories,
    rollbackOnErrorFn,
}) => {
    // index is for limit offset based pagination, but this API is cursor based.
    const getKey = (
        _index: number,
        previousPageData?: CursorPage_InboxNotification_
    ) => {
        if (previousPageData && previousPageData.next_page === null) {
            return null
        }
        const baseKey: Array<string | null | NotificationCategory[]> = [
            '/notifications/paginated',
        ]
        if (previousPageData && previousPageData.next_page) {
            baseKey.push(previousPageData.next_page)
        } else {
            baseKey.push(null)
        }
        if (categories?.length) {
            baseKey.push(categories.sort())
        }
        return baseKey
    }
    // isValidating is true when there is an ongoing request, even if data is loaded.
    // isLoading is true when there is no data. For useSWRInfinite, isValidating is more useful
    // for blocking further fetching because additional pages are appended to the original data.
    const { data, error, mutate, isLoading, isValidating, setSize } =
        useSWRInfinite(
            getKey,
            (key: Array<string | null>) => {
                return NotificationService.getNotificationsPaginatedApiV2NotificationsPaginatedGet(
                    categories,
                    /* cursor= */ key[1],
                    /* size= */ 50
                )
            },
            {
                // same options as useSWRImmutable
                revalidateOnFocus: false,
                revalidateOnReconnect: false,
                revalidateOnMount: true,
                revalidateIfStale: false,
                // Only the first page needs to be revalidated because of 2 reasons:
                // 1. Subsequent cursors to following pages will change, which will change the keys.
                // 2. We reset the size of the infinite page to 1 on notification list close.
                revalidateFirstPage: true,
                initialSize: 1,
                refreshInterval: NOTIFICATION_REFRESH_ONE_MINUTE_INTERVAL,
            }
        )

    const flattenedData = data?.flatMap((page) => page.items) ?? []
    const hasMore =
        !isValidating &&
        data?.length !== 0 &&
        data?.[data.length - 1]?.next_page !== null

    return {
        notifications: flattenedData,
        mutations: {
            markAsRead: (ids?: string[]) => {
                if (!data) {
                    return
                }
                for (const page of data) {
                    for (const item of page.items) {
                        if (ids === undefined) {
                            item.is_read = true
                        } else if (ids?.includes(item.id)) {
                            item.is_read = true
                        }
                    }
                }
                mutate(
                    async () => {
                        await NotificationService.markAsReadApiV1NotificationLogReadPut(
                            ids
                        )
                        return data
                    },
                    {
                        revalidate: false,
                        optimisticData: data,
                        rollbackOnError: rollbackOnErrorFn,
                    }
                )
            },
            deleteNotificationAndReturnIsRead: (id: string) => {
                if (!data) {
                    // if no data, return true so unread count is not decremented. This shouldn't really happen.
                    return true
                }
                const relevantNotification = data
                    .flatMap((page) => page.items)
                    .find((item) => item.id === id)
                if (!relevantNotification) {
                    // if notification not found, return true so unread count is not decremented. This shouldn't really happen.
                    return true
                }
                const newData = data.map((page) => ({
                    ...page,
                    items: page.items.filter((item) => item.id !== id),
                }))
                mutate(
                    async () => {
                        await NotificationService.deleteNotificationLogApiV1NotificationLogNotificationLogIdDelete(
                            id
                        )
                        return newData
                    },
                    {
                        revalidate: false,
                        optimisticData: newData,
                        rollbackOnError: rollbackOnErrorFn,
                    }
                )
                return relevantNotification.is_read
            },
        },
        hasMore,
        isLoading,
        isError: error,
        setSize,
    }
}

type UnreadNotificationsFunc = (categories?: NotificationCategory[]) => {
    unreadCount: number
    isLoading: boolean
    error: Error | null
    mutations: {
        setUnreadCount: (count: number) => void
        refetchUnreadCount: () => void
    }
}

export const useUnreadNotificationsCount: UnreadNotificationsFunc = (
    categories
) => {
    const { data, mutate } = useSWRImmutable(
        '/notifications/unread-count',
        () => {
            return NotificationService.getUnreadCountApiV1NotificationsUnreadCountGet(
                categories
            )
        },
        {
            refreshInterval: NOTIFICATION_REFRESH_ONE_MINUTE_INTERVAL,
        }
    )
    return {
        unreadCount: data ?? 0,
        isLoading: !data,
        error: null,
        mutations: {
            setUnreadCount: (count: number) =>
                mutate(count, {
                    revalidate: false,
                }),
            refetchUnreadCount: () => mutate(),
        },
    }
}

type NotificationsWithUnreadCountReturnType = {
    notifications: InboxNotification[]
    mutations: {
        deleteNotification: (id: string) => void
        markAsRead: (ids?: string[]) => void
    }
    hasMore: boolean
    setSize: (size: number) => void
    unreadCount: number
    isLoading: boolean
    isError: boolean
}

type NotificationsWithUnreadCountFunc =
    () => NotificationsWithUnreadCountReturnType

export const useNotificationsWithUnreadCount: NotificationsWithUnreadCountFunc =
    () => {
        const { settings } = useUserSettings()
        const inAppCategories =
            settings?.notification_settings.filter(
                (setting) =>
                    setting.delivery_method ===
                    UserNotificationDeliveryType.IN_APP
            ) ?? []

        const hasSomeDisabledInAppCategories = inAppCategories.some(
            (setting) => !setting.enabled
        )

        let categories: NotificationCategory[] | undefined

        // all disabled in-app categories is also covered by having an empty array.
        if (hasSomeDisabledInAppCategories) {
            categories = inAppCategories
                .filter((setting) => setting.enabled)
                .map((setting) => setting.notification_type)
        }

        const {
            unreadCount,
            mutations: { setUnreadCount, refetchUnreadCount },
        } = useUnreadNotificationsCount(categories)

        const rollbackOnErrorFn = () => {
            refetchUnreadCount()
            return true
        }

        const {
            notifications,
            mutations: { deleteNotificationAndReturnIsRead, markAsRead },
            hasMore,
            isLoading,
            isError,
            setSize,
        } = useNotifications({ categories, rollbackOnErrorFn })

        const markAsReadAndUpdateUnreadCount = (ids?: string[]) => {
            markAsRead(ids)
            if (!ids) {
                setUnreadCount(0)
            } else {
                setUnreadCount(Math.max(unreadCount - ids.length, 0))
            }
        }

        const deleteAndUpdateUnreadCount = (id: string) => {
            const isRead = deleteNotificationAndReturnIsRead(id)
            // if notification that was deleted was not read, decrement unread count accordingly.
            if (!isRead) {
                setUnreadCount(Math.max(unreadCount - 1, 0))
            }
        }

        return {
            notifications,
            mutations: {
                markAsRead: markAsReadAndUpdateUnreadCount,
                deleteNotification: deleteAndUpdateUnreadCount,
            },
            hasMore,
            isLoading,
            isError,
            setSize,
            unreadCount,
        }
    }
